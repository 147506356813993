* {
    padding: 0;
    margin: 0;
}

html,
body {
    background-color: rgb(252, 184, 3);
    color: black;
    font-family: 'Arial';
    height: 100%;
}

.wd-pw-field,
.wd-pw-field:only-child {
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
}

.wd-pw-field::placeholder {
    color: white;
}

.alert .alert-danger {
    background-color: red;
    color: black;
}

.infobox {
    border: 2px solid red;
    padding: 10px 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}